.information{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}
.textIntro{
    width: 100%;
}
.informationWrapper{
    width: 64%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap:20px;
    flex-direction: column;
    font-size: 33px;
    color: white;
    background-color: black;
    padding-top: 40px;
    padding-bottom: 40px;
}


@media (max-width:1800px){
    .informationWrapper{
        font-size: 30px;
    }
}

@media (max-width:1515px){
    .informationWrapper{
        font-size: 25px;
    }
}

@media (max-width:1050px){
    .informationWrapper{
        font-size: 26px;
        width: 70%;
    }
}
@media (max-width:1000px){
    .informationWrapper{
        width: 80%;
    }
}
@media (max-width:870px){
    .informationWrapper{
        width: 91%;
    }
}
@media (max-width:770px){
    .informationWrapper{
        width: 67%;
    }
}

@media (max-width:510px){
    .informationWrapper{
        font-size: 19px;
        width: 66%;
    }
}

@media (max-width:400px){
    .informationWrapper{
        width: 80%;
    }
}
@media (max-width:330px){
    .informationWrapper{
    width: 60%;
    }}
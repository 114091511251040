.leftSection{
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.leftSectionBackgroundImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -2;
}

.leftSectionBackground{
    width: 100%;
    height: 100%;
    clip-path: polygon(59% 0, 100% 0, 100% 100%, 30% 100%);
    background-color: black;
    z-index: 1;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.leftSectionBackgroundTexts{
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    justify-content: center;
    gap:30px;
    font-size: 28px;
    text-align: center;
    margin-right: 100px;
}


.leftSectionBackgroundTitle{
    font-size: 1.3em;
}

@media (max-width:1024px){
    .leftSectionBackgroundTexts{
        font-size: 25px;
    }
}

@media (max-width:768px){
    .leftSectionBackgroundTexts{
        font-size: 22px;
        margin-right: 50px;
    }
}

@media (max-width:660px){
    .leftSection{
        flex-direction: column;
        height: auto;
    }
    .leftSectionBackground{
        position: relative;
        clip-path: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .leftSectionBackgroundImage{
        position: relative;
    }
    .leftSectionBackgroundTexts{
        width: 90%;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}   

@media (max-width:660px){
    .leftSectionBackgroundTexts{
        font-size: 19px;
    }
}
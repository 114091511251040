.footer{
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 35px;
    margin-top: 20px;
    padding-bottom: 20px;
}
.poweredByImage{
    cursor: pointer;
}

@media (max-width:770px){
    .footer{
        margin-top: 0px;
    }
}
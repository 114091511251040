.locations{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
    padding-top:10px;
    padding-bottom: 20px;
    justify-content: center;
}

.locationsExamples{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    background-color: rgb(27, 27, 27);
}
@media (max-width:770px){
    .locations{
        padding-bottom: 0px;
    }
}

@media (max-width:660px){
    .lokacijeText{
        font-size: 30px !important;
    }
}
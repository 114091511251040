.map{
    width: 70%;
    height: 30%;
    position: absolute;
    bottom: 0;
}

.mapouter{
    overflow: hidden;
    position:"relative";
    text-Align:"center";
    width: 100%;
    height: 100%;
    border: none;
}


.gmap_canvas{
    overflow:"hidden";
    width:100%;
    height:100%;
}

.gmap_iframe{
    width:100%!important;
    height:100%!important;
}


@media (max-width:660px){
    .map{
        width: 100%;
    }
}
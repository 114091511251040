.brandIconLink{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    transition: 0.1s ease-out;
}
.brandIconLink:hover{
    transform: scale(1.1);
    transition: 0.1s ease-in;
}
.brandIcon{
    height:100%;
    width: 100%;
    object-fit: contain;
}
.customBrandIcon{
    transform: scale(0.8);
}

@media (max-width:690px){
    .brandIconLink{
        height: 65%;
    }
}

@media (max-width:360px){
    .brandIconLink{
        height: 55%;
    }

}
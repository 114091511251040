.topbarMobile {
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    display: none;
    color: white;
    text-align: center;
    font-size: 27px;
    transition: 0.3s ease;
}

.topbarMobileBrands {
    display: grid;
    grid-template-columns: 1fr 1fr  1fr;
    align-items: center;
    justify-content: space-evenly;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 100px;
    opacity: 1;
    transform: scale(0.9); 
  }
.hideTopbarMobileBrands {
    max-height: 0; 
    opacity: 0; 
}
.topbarMobileIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease;
    transform: scale(0.96); 
}

.smallMarginTop{
    margin-top: 10px;
}

.topbarMobileIconsSocial{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
    margin-left: 10px;
}
.sideMenuImageMobile{
    width: 30px;
    height: 30px;
    margin-right: 17px;
}


@media (max-width:690px){
    .topbarMobile{
        display: grid;
        grid-template-rows: 1fr;
        padding-right: 15px;
    }
    .sideMenuImageMobile{
        width: 23px;
        height: 23px;
    }
}
@media (max-width:455px){
    .sideMenuImageMobile{
        margin-right: 12px;
    }
}

@media (max-width:410px){
    .sideMenuImageMobile{
        width: 20px;
        height: 20px;
    }
    .topbarMobile{
        transform: scale(1.1)!important;
        padding-right: 10px;
    }
}

@media (max-width:375px){
    .sideMenuImageMobile{
        margin-right: 10px;
    }
}

@media (max-width:365px){
    .sideMenuImageMobile{
        width: 16px;
        height: 16px;
        margin-right: 13px;
    }
}
@media (max-width:320px){
    .sideMenuImageMobile{
        margin-right: 10px;
    }
}
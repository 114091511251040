.sidebar {
    color: white;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 4;
    background-color: black;
    height: 100vh;
    width: 300px;
    transition: transform 0.32s ease-in;
    transform: translateX(0);
  }
  
  .sidebarClosed {
    transform: translateX(100%);
  }
  
  .closeSidebarImageWrapper {
    height: 26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .closeSidebarImage {
    height: 100%;
    margin-right: 20px;
    margin-top: 25px;
    opacity: 100%;
    transform: translateX(0);
    transition: transform 0.32s ease-in;
    cursor: pointer;
  }

.sidebarButtons{
    height: 80%;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: top;
    font-size: 25px;
    margin-top: 50px;
    gap:20px;
    text-indent: 20px;
}

.sidebarButton{
    height: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor:pointer;
    z-index: 5;
    min-width: 300px;
}

.sidebarButton:hover{
    color: black;
    background-color:white;
    height: 70px;
    text-indent: 20px;
    cursor: pointer;
}

@media (max-width:570px){
  .sidebarButton{
    font-size: 20px;
    height: 60px;
  }
  .sidebarButtons{
    gap: 0px;
  }
}
@media (max-width:400px){
    .sidebar{
        width: 100%;
    }
    .sidebarButton{
      font-size: 22px;
    }
}
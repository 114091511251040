.socialIconLink{
    height: 40px;
    width: 40px;
    transition: 0.1s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
.socialIconLink:hover{
    transform: scale(1.1);
    transition: 0.1s ease-in;
}
.socialIcon{
    height:100%;
    width: 100%;
    margin-top: 3px;
}

@media (max-width:1080px){
    .socialIcon{
        width: 32px;
        height: 32px;
    }
}

@media (max-width:715px){
    .socialIconLink{
        width: 30px;
        height: 30px;
    }
}

@media (max-width:690px){
    .socialIcon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
    }
}
@media (max-width:560px){
    .socialIcon{
        margin-left: -10px;
    }
}

@media (max-width:410px){
    .socialIcon{
        width: 20px;
        height: 20px;
        margin-left: -20px;
    }
}

@media (max-width:360px){
    .socialIcon{
        width: 18px;
        height: 18px;
        margin-left: -15px;
    }
}

@media (max-width:360px){
    .socialIcon{
        margin-left: -22px;
    }
}
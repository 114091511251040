.topbar {
    height: 7vh;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
}

.topbarWrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
}
.topbarWrapperLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-left: 10px;
}


.hideTopbarWrapperCenter {
    opacity: 0!important;
    transition: opacity 0.3s ease;
  }
.topbarWrapperCenter{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.topbarWrapperRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 30px;
}

.sideMenuImage{
    width: 35px;
    height: 35px;
    transition: 0.3s ease;
    cursor: pointer;
}

.sideMenuImage:hover{
    scale: 1.3;
    transition: 0.3s ease;
}

@media (max-width:1080px){
    .sideMenuImage{
        width: 30px;
        height: 30px;
    }
}
@media (max-width:800px){
    .topbarWrapperCenter{
        gap:8px;
    }
}
@media (max-width:715px){
    .topbarWrapperCenter{
        gap: 5px;
    }
    .sideMenuImage{
        width: 28px;
        height: 28px;
    }
}
@media (max-width:690px){
    .topbar{
        display: none;
    }
}

.mainImages{
    position: relative;
    overflow: hidden;
    z-index: 1;
    /* width: 100vw; */
    height: 100vh;
  }
  .defaultMainImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    filter: brightness(60%);
    transition: 0.2s ease;
  }
  
  .defaultMainImageScaled{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(60%);
    transition: 5s ease;
  }
  
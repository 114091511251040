.intro {
    display: flex;
    align-items: center;
    justify-content: top;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 0;
    color: white;
    z-index: 2;
    margin-top: 200px;
}
.vaLogo{
    transform: scale(0.7);
}
.oxfordLogo{
    transform: scale(0.8);
}
.nikolaBozin{
    transform: scale(0.9);
}
.click {
    font-size: 90px;
}

.clickLogo {
    height: 30%;
}

.ultimativni {
    text-align: center;
    font-size: 50px;
    width: 100%;
}

.poweredBy {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    flex-direction: column;
}
.powerByImages{
    display: flex;
    align-items: center;
    justify-content: center;
}
.poweredByImage {
    width: 280px;
    height: 200px;
    object-fit: contain;
}
@media (max-width:1310px){
    .click{
        font-size: 80px;
    }
    .ultimativni{
        font-size: 48px;
    }

}
@media (max-width:930px){
    .click{
        font-size: 77px;
    }
    .ultimativni{
        font-size: 45px;
    }
}
@media (max-width:850px){
    .ultimativni{
        font-size: 35px;
    }
    .poweredByImage{
        width: 150px;
        height: 150px;
    }
    /* .poweredBy{
        margin-top: 150px;
    } */
}
@media (max-width:650px){
    .click{
        font-size: 68px;
        margin-top: 20px;
    }
    .ultimativni{
        width: 70%;
        font-size: 40px;
    }
    /* .poweredBy{
        margin-top: 90px;
    } */
}
@media (max-width:530px){
    .ultimativni{
        font-size: 31px;
    }
    .poweredByImage{
        width: 140px;
        height: 140px;
    }
    /* .poweredBy{
        margin-top: 105px;
    } */
}
@media (max-width:460px){
    .click{
        font-size: 50px;
    }
    .ultimativni{
        font-size: 30px;
    }
    .poweredByImage{
        width: 120px;
        height: 120px;
    }
    /* .poweredBy{
        margin-top: 140px;
    } */
}
@media (max-width:360px){
    .click{
        font-size: 40px;
        margin-top: 40px;
    }
    .ultimativni{
        width: 42%;
    }
    .poweredByImage{
        width: 100px;
    }
    /* .poweredBy{
        margin-top: 100px;
    } */
}